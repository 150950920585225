import { GroupTabs } from "@shared/utils/tab-helper";
import { createRouter } from "@uxf/router";

export type RouteList = {
    "main/index": null;
    "main/events": null;
    "main/contacts": null;
    "main/partners": null;
    "main/sentry-test": null;
    sitemap: "/sitemap.xml";

    // auth-zone
    "auth/login": null;
    "auth/registration": null;
    "auth/password-set": { token: string };

    // content-zone
    "content/content": {
        contentId: number;
        slug?: string;
    };
    "content/contents": null;

    ////////////////////////////////////////
    // member zone
    "member/index": null;
    "member/profile": null;
    "member/trainings": {
        groupId?: number;
        slug?: string;
        tab?: GroupTabs;
    };
    "member/attendance": {
        trainingId: number;
    };
    // content-zone
    "member/content": {
        contentId: number;
        slug?: string;
    };
    // advert zone
    "member/adverts": null;
    "member/new-advert": null;
};

export default createRouter<RouteList>({
    "main/index": "/",
    "main/events": "/akce",
    "main/contacts": "/kontakty",
    "main/partners": "/partneri",
    "main/sentry-test": "/sentry-test",
    sitemap: "/sitemap.xml",

    // auth-zone
    "auth/login": "/prihlaseni",
    "auth/registration": "/registrace",
    "auth/password-set": "/nastavit-heslo/[token]",

    // content-zone
    "content/contents": "/aktuality",
    "content/content": "/clanek/[contentId]/[slug]",

    // training zone
    "member/index": "/pro-cleny",
    "member/profile": "/pro-cleny/profil",
    "member/trainings": "/pro-cleny/[groupId]/[slug]",
    "member/attendance": "/pro-cleny/dochazka/trenink/[trainingId]",
    "member/content": "/pro-cleny/clanek/[contentId]/[slug]",

    // advert zone
    "member/adverts": "/pro-cleny/bazar",
    "member/new-advert": "/pro-cleny/bazar/novy-inzerat",
});
