import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { flashMessage } from "@uxf/ui/flash-messages/flash-messages-service";

function logError(error: any): void {
    Sentry.captureException(error);
}

function getErrorMessage(error: any): string | undefined {
    if (error.message) {
        return error.message;
    }
}

/**
 * @deprecated Use handleErrorWithMessage instead
 */
function handleError(error: any): void {
    const errorMessage = getErrorMessage(error);

    if (!errorMessage) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    flashMessage({ variant: "error", message: errorMessage ?? "Vyskytla se chyba." });
}

function handleErrorWithMessage(error: any, errorMessage: string): void {
    logError(error);

    if (error instanceof ApolloError) {
        if (getErrorMessage(error)) {
            flashMessage({ variant: "error", message: getErrorMessage(error) });
        } else {
            flashMessage({ variant: "error", message: error.message });
        }
    } else {
        flashMessage({ variant: "error", message: errorMessage });
    }
}

export const ErrorService = {
    logError,
    handleError,
    handleErrorWithMessage,
};
